import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { SearchContext } from '../../project/building/Building';
import MainContainer from '../../../containers/MainContainer';
import NLeftPanel from '../../../shared/components/NLeftPanel';
import UsersDetailView from './components/UsersDetailView';
import InvitePeopleModal from './components/modals/InvitePeopleModal';
import { useQuery } from 'react-query';
import { apiService } from '../../../utills/api';

export const usersQueryKey = 'employee';

function Users() {
  const [search, setSearch] = useState(null);
  const [filter, setFilter] = useState({ is_active: [true] });
  const [activeItemId, setActiveItemId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const current = useSelector((state) => state.auth.current_company);

  const { pagePermission } = useSelector((state) => state.auth);

  const { data: roles } = useQuery({
    queryKey: ['roles'],
    queryFn: async () => {
      const { data } = await apiService.get(`/api/v1/company/roles/`);
      return data;
    },
    refetchOnWindowFocus: false,
  });

  const getDetail = (id) => {
    setActiveItemId(id);
  };

  const onSearch = async (e) => {
    setSearch(e?.target?.value);
  };

  return (
    <SearchContext.Provider
      value={{
        search,
        filter,
        setSearch,
        setFilter,
      }}
    >
      <MainContainer>
        <NLeftPanel
          queryKey={usersQueryKey}
          makeActive={getDetail}
          active={activeItemId}
          onSearch={onSearch}
          addItem={() => setOpenDialog(true)}
          title="Lidé ve firmě"
          permission={pagePermission}
          filteredValues={{
            is_active: [
              { value: true, title: 'Aktivní' },
              { value: false, title: 'Neaktivní' },
            ],
            role: (roles || []).map((ele) => {
              ele.value = ele.id;
              return ele;
            }),
          }}
        />

        {activeItemId && <UsersDetailView userId={activeItemId} roles={roles} />}
      </MainContainer>

      <InvitePeopleModal
        visible={openDialog}
        onClose={() => setOpenDialog(false)}
        current={current}
        roles={roles}
      />
    </SearchContext.Provider>
  );
}

export default Users;
