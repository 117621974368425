import React, { useState, useEffect } from 'react';
import ModalButton from '../../../../../shared/modal/ModalButton';
import Modal from '../../../../../shared/modal/Modal';
import style from './licenceModal.module.css';
import { apiService } from '../../../../../utills/api';
import BillList from '../BillList';
import { EditOutlined, FileSearchOutlined } from '@ant-design/icons';
import Bar from '../../../../../elements/Bar';
import BillForm from '../BillForm';
import { useDispatch, useSelector } from 'react-redux';
import { editCompany } from '../../../../../store/actions/company';
import List from '../../../../../elements/List';
import { LICENCE } from '../../../../../constants';
import classNames from 'classnames';
import { format } from 'date-fns';
import { Edit12Regular } from '@fluentui/react-icons';
import ChangeLicenceModal from './ChangeLicenceModal';

const getLicences = async () => {
  return await apiService.get('/api/v1/company/licence/');
};

const prepareDataForLincence = (data) => {
  console.log({ data });

  const dateFrom = data.licence?.valid_from
    ? format(new Date(data.licence?.valid_from), 'dd.MM.yyyy')
    : '';

  return [
    { name: 'Atuální plán', value: LICENCE[data.licence?.licence_type]?.description },
    {
      name: 'Cena za měsíc',
      value: `${data.licence?.price_per_employee_no_vat} KČ bez DPH za osobu`,
    },
    {
      name: `Za aktuální období ${dateFrom}`,
      value: `${data.licence?.monthly_price_no_vat} KČ bez DPH`,
    },
  ];
};

const LicenceCompanyModal = ({ visible, onClose }) => {
  const detail = useSelector((state) => state?.company?.detail);
  const [openChangeLicenceDialog, setOpenChangeLicenceDialog] = useState(false);

  const [submit, setSubmit] = useState(false);
  // const [licences, setLicences] = useState([]);
  const [stage, setStage] = useState(1);

  const dispatch = useDispatch();

  if (!detail) {
    return null;
  }

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       let [{ data: _licences }, { data: _bills }] = await Promise.all([getLicences()]);
  //       if (_licences && _bills) {
  //         setLicences(_licences);
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };

  //   fetchData();

  //   return () => {
  //     setLicences([]);
  //   };
  // }, []);

  const footer = () => {
    let result = [
      <ModalButton key={1} action={() => onClose()}>
        Zavřít
      </ModalButton>,
    ];

    result.push(
      stage === 0 && (
        <ModalButton key={1} action={() => {}}>
          Stáhnout vše
        </ModalButton>
      )
    );

    result.push(
      <ModalButton primary key={2} action={() => setSubmit(true)}>
        Uložit
      </ModalButton>
    );
    return result;
  };

  const formSubmit = async (values) => {
    setSubmit(false);
    dispatch(editCompany(values));
    onClose();
  };

  return (
    <>
      <Modal
        title={'Předplatné a fakturace'}
        visible={visible}
        header={[
          <ModalButton
            key={1}
            primary={stage === 0}
            action={() => {
              setStage(0);
            }}
          >
            <FileSearchOutlined />
          </ModalButton>,
          <ModalButton
            key={1}
            primary={stage === 1}
            action={() => {
              setStage(1);
            }}
          >
            <EditOutlined />
          </ModalButton>,
        ]}
        className={style.modal}
        footer={footer()}
      >
        {stage === 1 ? (
          <>
            <Bar
              text="Licenční plán"
              content={[
                <button key={1} onClick={() => setOpenChangeLicenceDialog(true)}>
                  <Edit12Regular />
                </button>,
              ]}
            />
            <List data={prepareDataForLincence(detail)} />
            <Bar text="E-mail pro zasílání elektronických faktur" className="mt-2" />
            <div className={classNames(style.billMailContainer, 'mt-2')}>
              <BillForm
                formProps={{ invoicing_email: detail?.invoicing_email }}
                submitOutside={submit}
                submit={formSubmit}
              />
            </div>
          </>
        ) : (
          <BillList data={[]} />
        )}
      </Modal>
      <ChangeLicenceModal
        visible={openChangeLicenceDialog}
        onClose={() => setOpenChangeLicenceDialog(false)}
      />
    </>
  );
};

export default LicenceCompanyModal;
