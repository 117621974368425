import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Popconfirm } from 'antd';
import moment from 'moment';
import Bar from '../../../elements/Bar';
import List from '../../../elements/List';
import BaseContainer from '../../../containers/BaseContainer';
import MainContainer from '../../../containers/MainContainer';
import EditCompanyModal from './components/modals/EditCompanyModal';
import {
  editCompany,
  updateMembers,
  fetchMoreEmployees,
  loadCompanyDetail,
  updateStorage,
} from '../../../store/actions/company';
import LicenceCompanyModal from './components/modals/LicenceCompanyModal';
import SharedModlalToAddItemFromList from '../../../shared/modals/SharedModlalToAddItemFromList';

const StorageModal = lazy(() => import('./components/modals/StorageModal'));

const detailData = (data) => {
  if (!data) return [];
  const { ico, dic, title, address, description } = data;
  return [
    { name: 'IČ', value: ico },
    { name: 'DIČ', value: dic },
    { name: 'Název', value: title },
    { name: 'Sídlo', value: address },
    { name: 'Zaměření', value: description },
  ];
};

const detailStorage = (data) => {
  if (!data || Object.keys(data).length === 0 || !data.storage_info) return [];
  const {
    storage_info: { capacity, status_data, type, used },
  } = data;
  return [
    { name: 'Název služby', value: type },
    { name: 'Stav', value: status_data },
    { name: 'Účet', value: used },
    { name: 'Dostupná kapacita', value: capacity },
  ];
};

const licenceData = (data) => {
  if (!data) return [];
  const {
    licence_status,
    licence_active_plan,
    invoicing_email,
    licence_last_payday,
    licence_next_payday,
  } = data;
  return [
    { name: 'Stav', value: licence_status },
    { name: 'Aktuální plán', value: licence_active_plan },
    { name: 'Faktury zasílat na', value: invoicing_email },
    {
      name: 'Datum poslední platby',
      value: moment(licence_last_payday || '').format('DD.MM.YYYY'),
    },
    {
      name: 'Datum příští platby',
      value: moment(licence_next_payday || '').format('DD.MM.YYYY'),
    },
  ];
};

const adminData = (data) => {
  if (!data) return [];
  const { administrators } = data;

  return (administrators || []).map((ele) => ({
    name: ele.user_name,
    value: ele.user_mail,
    id: ele?.id,
  }));
};

function Company() {
  const [visible, setVisible] = useState(false);
  const [visibleLicences, setVisibleLicences] = useState(false);
  const [addEmployeesVisible, setAddEmployeesVisible] = useState(false);
  const [error, setError] = useState(null);
  const [nextEmployees, setNextEmployees] = useState(null);
  const [searchInEmployees, setSearchInEmployees] = useState(null);
  const [visibleStorage, setVisibleStorage] = useState(false);

  const { current_company: current } = useSelector((state) => state.auth);

  const permission = useSelector((state) => state.auth.pagePermission);
  const _company = useSelector((state) => state.company);

  const { detail: fetchedCompany, employees } = _company;

  const dispatch = useDispatch();

  useEffect(() => {
    if (current) {
      dispatch(loadCompanyDetail());
      fetchEmployees();
    }
  }, []);

  useEffect(() => {
    if (current !== fetchedCompany?.id) {
      dispatch(loadCompanyDetail());
    }
  }, [current]);

  const makeModalVisible = () => {
    setVisible(true);
  };

  const fetchEmployees = async (searchTerm) => {
    try {
      const { next } = await dispatch(fetchMoreEmployees(nextEmployees, searchTerm));
      setSearchInEmployees(searchTerm);
      setNextEmployees(next);
    } catch (e) {
      console.log(e);
    }
  };

  const onClose = () => {
    setVisible(false);
    setVisibleStorage(false);
    setError(null);
  };

  const submitEditForm = async (values) => {
    const { status, error } = dispatch(editCompany(values));

    if (status === true) {
      setVisible(false);
      setError(null);
    }
    if (error) {
      setError(error);
    }
  };

  const deleteButton = (values) => (
    <Popconfirm
      placement="left"
      title="Opravdu chcete odstranit zaměstnance?"
      onConfirm={() => dispatch(updateMembers(values, 'remove_from'))}
      okText="Ano"
      cancelText="Ne"
      icon={null}
    >
      <MinusOutlined key={1} />
    </Popconfirm>
  );

  const addAdmin = (data) => {
    setAddEmployeesVisible(false);
    dispatch(updateMembers(data, 'add_to'));
  };

  const onSearchEmployees = async (e) => {
    const searchTerm = e?.target?.value;
    fetchEmployees(searchTerm);
  };

  const fetchMore = useCallback(async () => {
    if (nextEmployees !== null || nextEmployees !== 1) {
      try {
        const { next } = dispatch(fetchMoreEmployees(nextEmployees, searchInEmployees));
        setNextEmployees(next);
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const submitStorageForm = async (values) => {
    const { status, error } = dispatch(updateStorage(values));

    if (status) {
      setVisibleStorage(false);
      setError(null);
    }
    setError(error);
  };

  //! storage modal is now unused and is waiting for new API
  const _renderDataStorage = () => (
    <BaseContainer margin width="100%">
      <Bar
        text="Datový sklad"
        content={
          permission === 2 ? [<EditOutlined key={1} onClick={() => setVisibleStorage(true)} />] : []
        }
      />
      <List data={detailStorage(fetchedCompany)} />
    </BaseContainer>
  );

  const preparedAdminData = adminData(fetchedCompany);

  return (
    <>
      <MainContainer>
        <BaseContainer fullHeight margin width="100%">
          <BaseContainer margin width="100%">
            <Bar
              text="Detail firmy"
              content={
                permission === 2 ? [<EditOutlined key={1} onClick={makeModalVisible} />] : []
              }
            />
            <List data={detailData(fetchedCompany)} />
          </BaseContainer>
          <BaseContainer margin width="100%">
            <Bar
              text="Předplatné a fakturace"
              content={
                permission === 2
                  ? [<EditOutlined key={1} onClick={() => setVisibleLicences(true)} />]
                  : []
              }
            />
            <List data={licenceData(fetchedCompany)} />
          </BaseContainer>
          <BaseContainer margin width="100%">
            <Bar
              text="Správci firmy"
              content={
                permission === 2
                  ? [<PlusOutlined key={1} onClick={() => setAddEmployeesVisible(true)} />]
                  : []
              }
            />
            <List
              data={preparedAdminData}
              actions={preparedAdminData.length > 1 ? [deleteButton] : []}
            />
          </BaseContainer>
          {/* {_renderDataStorage()} */}
          {/* <GoogleClient /> */}
        </BaseContainer>
      </MainContainer>
      <EditCompanyModal
        title="Editace firmy"
        data={fetchedCompany}
        visible={visible}
        submitEditForm={submitEditForm}
        error={error}
        onClose={onClose}
      />
      <SharedModlalToAddItemFromList
        data={employees}
        title="Přidat správce"
        visible={addEmployeesVisible}
        onSearch={onSearchEmployees}
        hasMore={nextEmployees !== null}
        fetchMore={fetchMore}
        onClose={() => setAddEmployeesVisible(false)}
        submit={addAdmin}
      />
      {fetchedCompany && (
        <LicenceCompanyModal visible={visibleLicences} onClose={() => setVisibleLicences(false)} />
      )}
      <Suspense fallback={<div>Loading Component</div>}>
        {visibleStorage && (
          <StorageModal
            visible={visibleStorage}
            submitStorageForm={submitStorageForm}
            error={error}
            onClose={onClose}
          />
        )}
      </Suspense>
    </>
  );
}

export default Company;
