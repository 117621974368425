import React, { useState } from 'react';
import ModalButton from '../../../../../shared/modal/ModalButton';
import Modal from '../../../../../shared/modal/Modal';
import UsersForm from '../UsersForm';

const UsersModal = ({ visible, onClose, data, submitEditForm, error, roles }) => {
  const [submit, setSubmit] = useState(false);
  const [reset, setReset] = useState(false);

  const formSubmit = (values) => {
    let _id = data ? data?.id : undefined;

    submitEditForm(_id, values);
    setSubmit(false);
  };

  const buttons = () => {
    let _buttons = [
      <ModalButton key={1} action={() => onClose()}>
        Zavřít
      </ModalButton>,
      <ModalButton primary key={2} action={() => setSubmit(true)}>
        Potvrdit
      </ModalButton>,
    ];

    return _buttons;
  };

  return (
    <Modal title={'Editace pracovníka'} visible={visible} header={[]} footer={buttons()}>
      <UsersForm
        submitOutside={submit}
        formProps={data || {}}
        error={error}
        reset={reset}
        roles={roles}
        resetConfirm={() => setReset(false)}
        submit={formSubmit}
      />
    </Modal>
  );
};

export default UsersModal;
