import { EditOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import BaseContainer from '../../../../containers/BaseContainer';
import Bar from '../../../../elements/Bar';
import List from '../../../../elements/List';
import { useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { apiService } from '../../../../utills/api';
import { Spin } from 'antd';
import UsersModal from './modals/UsersModal';
import { usersQueryKey } from '../Users';
import { NotificationManager } from 'react-notifications';

function UsersDetailView({ userId, roles }) {
  const permission = useSelector((state) => state.auth.pagePermission);
  const queryClient = useQueryClient();

  const [userModalOpen, setUserModalOpen] = useState(false);

  const edit = useMutation({
    mutationFn: ({ id, values }) => {
      console.log({ values });
      return apiService.patch(`/api/v1/employee/${id}/`, values);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(usersQueryKey);
      setUserModalOpen(false);
    },
    onError: (e) => {
      NotificationManager.error('Nastala chyba', e?.response?.message);
    },
  });

  const { data, isFetching } = useQuery({
    queryKey: ['user', userId],
    enabled: !!userId,
    queryFn: async () => {
      const { data } = await apiService.get(`/api/v1/employee/${userId}/`);
      return data;
    },
    refetchOnWindowFocus: false,
  });

  if (!userId) {
    return null;
  }

  if (isFetching) {
    return (
      <BaseContainer fullHeight={true} width={'calc(100% - 420px)'} margin>
        <div className="flex w-full h-full items-center justify-center">
          <Spin />
        </div>
      </BaseContainer>
    );
  }

  const prepareDataDetail = () => {
    if (!data) return [];

    return [
      { name: 'Uživatel', value: data?.user_name },
      { name: 'Uživatelský email', value: data?.user_email },
    ];
  };

  const prepareDataSpecificRole = () => {
    if (!data) return [];

    return [
      {
        name: 'Role',
        value: data?.role?.title,
      },
      {
        name: 'Přiřazený pracovní email',
        value: data?.employee_email,
      },
      {
        name: 'Název pracovní pozice',
        value: data?.employee_job_title,
      },
      {
        name: 'Stav',
        value: data?.employee_status ? 'aktivní' : 'neaktivní',
      },
      {
        name: 'Datum nástupu pracovníka',
        value: data?.employee_employed_start,
      },
      {
        name: 'Datum odchodu pracovníka',
        value: data?.employee_employed_end,
      },
      {
        name: 'Doba ve firmě',
        value: data?.employee_employed_total_time,
      },
      {
        name: 'Datum přidání do firmy',
        value: (data?.employee_confirmed || '').substr(0, 10),
      },
      {
        name: 'Datum posledního přihlášení',
        value: (data?.employee_last_login || '').substr(0, 10),
      },
    ];
  };

  const content = () => {
    if (permission === 2) {
      return [<EditOutlined key={1} onClick={() => setUserModalOpen(true)} />];
    }
    return [];
  };

  return (
    <div>
      <BaseContainer fullHeight={true} width={'calc(100% - 420px)'} margin>
        <Bar text={'Informace převzaté z uživatelského účtu'} content={[]} />
        <List data={prepareDataDetail()} />
        <Bar text={'Informace specifické pro roli ve firmě'} content={content()} />
        <List data={prepareDataSpecificRole()} actions={[]} />
      </BaseContainer>

      <UsersModal
        visible={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        data={data}
        submitEditForm={(id, values) => edit.mutate({ id, values })}
        error={{}}
        roles={roles ?? []}
      />
    </div>
  );
}

export default UsersDetailView;
