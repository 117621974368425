import React, { useEffect, useRef } from 'react';
import { Formik, Field } from 'formik';
import { Form } from 'antd';
import { InputField } from '../../../../shared/InputField';
import { SelectField } from '../../../../shared/SelectField';
import { DatePickerField } from '../../../../shared/DatePickerField';
import * as Yup from 'yup';
import { validDate } from '../../../../utills/time';

const ValidationSchema = Yup.object().shape({
  employee_email: Yup.string().email('Zadejte validní e-mail!').nullable(true),
});

const UsersForm = ({
  formProps,
  submitOutside,
  submit,
  error,
  reset = false,
  resetConfirm,
  roles,
  isNewEmployee = false,
}) => {
  const formRef = useRef();

  useEffect(() => {
    if (reset === true) {
      formRef.current.setValues(init(formProps));
      resetConfirm();
    }
  }, [reset]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed && submitOutside) {
      formRef.current.submitForm();
    }

    return () => (isSubscribed = false);
  }, [submitOutside]);

  useEffect(() => {
    formRef.current.setErrors(error?.response?.data || '');
  }, [error]);

  const init = ({
    role,
    employee_email,
    employee_job_title,
    employee_status,
    employee_employed_start,
    employee_employed_end,
  }) => {
    let employeeRole = role?.id;
    return {
      role: employeeRole,
      employee_email,
      employee_job_title,
      employee_status,
      employee_employed_start,
      employee_employed_end,
    };
  };
  return (
    <div>
      <Formik
        validationSchema={ValidationSchema}
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={init(formProps)}
        onSubmit={(values) => {
          submit(values);
        }}
      >
        {(props) => {
          const { values, setFieldValue } = props;

          return (
            <Form labelCol={{ span: 9 }}>
              <Form.Item label="Označení">
                <Field
                  name="role"
                  value={values.role}
                  onChange={(val) => {
                    setFieldValue('role', val);
                  }}
                  data={roles}
                  placeholder="Role"
                  component={SelectField}
                />
              </Form.Item>
              <Form.Item label="Přiřazený pracovní email">
                <Field
                  name="employee_email"
                  value={values.employee_email}
                  placeholder="Přiřazený pracovní email"
                  component={InputField}
                />
              </Form.Item>
              <Form.Item label="Název pracovní pozice">
                <Field
                  name="employee_job_title"
                  value={values.employee_job_title}
                  placeholder="Název pracovní pozice"
                  component={InputField}
                />
              </Form.Item>
              {!isNewEmployee && (
                <Form.Item label="Stav">
                  <Field
                    name="employee_status"
                    value={values.employee_status}
                    placeholder="Stav"
                    onChange={(val) => {
                      setFieldValue('employee_status', val);
                    }}
                    data={[
                      {
                        id: true,
                        title: 'aktivní',
                      },
                      { id: false, title: 'neaktivní' },
                    ]}
                    component={SelectField}
                  />
                </Form.Item>
              )}
              <Form.Item label="Datum nástupu pracovníka">
                <Field
                  name="employee_employed_start"
                  value={validDate(values.employee_employed_start)}
                  placeholder="Datum nástupu pracovníka"
                  onChange={(date) => setFieldValue('employee_employed_start', date)}
                  component={DatePickerField}
                />
              </Form.Item>
              {!isNewEmployee && (
                <Form.Item label="Datum odchodu pracovníka">
                  <Field
                    name="employee_employed_end"
                    value={validDate(values.employee_employed_end)}
                    placeholder="Datum odchodu pracovníka"
                    component={DatePickerField}
                    onChange={(date) => setFieldValue('employee_employed_end', date)}
                  />
                </Form.Item>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default UsersForm;
