import React, { Component } from 'react';
import style from './dashboard.module.css';
import Img from '../../images/dashboard.png';

export default class Dashboard extends Component {
  render() {
    if (this.props.match.path !== this.props.location.pathname) return null;

    return (
      <div className={style.dashboard}>
        <div className="max-w-96 select-none">
          <img src={Img} />
        </div>
      </div>
    );
  }
}
