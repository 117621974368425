import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  access_token: null,
  refresh_token: null,
  error: null,
  user: {
    first_name: '',
    last_name: '',
    email: '',
    pk: '',
    image_url: '',
    degree_bedore: '',
    degree_after: '',
  },
  employees: [],
  current_company: null,
  current_employee: null,
  licence: 'free',
  loading: false,
  loadingProfile: false,
  pagePermission: null,
  errorRegistration: null,
  successRegistration: null,
};

const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    access_token: action.data.access,
    refresh_token: action.data.refresh,
    user: action.data.user,
    error: null,
    loading: false,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const authLogout = (state, action) => {
  return updateObject(state, undefined);
};

const userProfileStartLoading = (state, action) => {
  return {
    ...state,
    loadingProfile: true,
  };
};

const userProfileSuccess = (state, action) => {
  return {
    ...state,
    loadingProfile: false,
    employees: action.data.employees,
    current_employee: action.data?.current_employee?.id,
    current_company: action.data?.current_employee?.company?.id,
    licence: action.data?.current_employee.company.licence_plan ?? 'free',
  };
};

const changeCurrentCompany = (state, action) => {
  return {
    ...state,
    current_employee: action.id,
    current_company: action.company,
  };
};

const pagePermissionSucc = (state, action) => {
  return {
    ...state,
    pagePermission: action.data,
  };
};

const registrationFail = (state, action) => {
  return {
    ...state,
    errorRegistration: action.data,
  };
};

const registrationSucc = (state, action) => {
  return {
    ...state,
    errorRegistration: null,
    successRegistration: action.data,
  };
};

const refreshConnectionSucc = (state, action) => {
  return {
    ...state,
    access_token: action.access,
  };
};

const updateUserSuccessfull = (state, action) => {
  return {
    ...state,
    user: { ...state.user, ...action.data },
  };
};

const changeLicence = (state, action) => {
  return {
    ...state,
    licence: action.data,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.START_LOADING_USER_PROFILE:
      return userProfileStartLoading(state, action);
    case actionTypes.USER_PROFILE_LOADED:
      return userProfileSuccess(state, action);
    case actionTypes.CHANGE_CURRENT_COMPANY:
      return changeCurrentCompany(state, action);
    case actionTypes.REGISTRATION_FAIL:
      return registrationFail(state, action);
    case actionTypes.REGISTRATION_SUCC:
      return registrationSucc(state, action);
    case actionTypes.REGISTRATION_CONFIRM:
      return state;
    case actionTypes.REFRESH_CONNECTION_SUCCESS:
      return refreshConnectionSucc(state, action);
    case actionTypes.SET_PAGE_PERMISSION:
      return pagePermissionSucc(state, action);
    case actionTypes.UPDATE_USER_SUCCESSFULLY:
      return updateUserSuccessfull(state, action);
    case 'CHANGE_LICENCE':
      return changeLicence(state, action);
    default:
      return state;
  }
};

export default reducer;
