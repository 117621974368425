import { NotificationManager } from 'react-notifications';
import { apiService } from '../../utills/api';

const getEmployees = async () => await apiService.get(`/api/v1/employee/left-panel`);
export const geCompanyDetail = async (id) => await apiService.get(`/api/v1/company/${id}/`);

export const loadCompanyDetail = () => async (dispatch, getState) => {
  dispatch({ type: 'START_LOADING_COMPANY' });

  try {
    const [{ data: employees }, { data: comDetail }] = await Promise.all([
      getEmployees(),
      geCompanyDetail(getState().auth.current_company),
    ]);

    if (employees && comDetail) {
      dispatch({ type: 'COMPANY_LOADED_SUCCESSFULL', data: comDetail });
      dispatch({
        type: 'EMPLOYEES_LOADED_SUCSESSFULL',
        data: employees?.results,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const editCompany = (values) => async (dispatch, getState) => {
  let status = null;
  let error = null;

  try {
    status = (await apiService.patch(`/api/v1/company/${getState().auth.current_company}/`, values))
      .status;
  } catch (err) {
    error = err;
  }
  return new Promise((resolve, reject) => {
    if (status && status === 200) {
      dispatch({ type: 'COMPANY_EDITED_SUCCESSFULL', data: values });
      resolve({ status: true, error });
    }
    resolve({ status: false, error });
  });
};

export const updateMembers =
  (values, action = null) =>
  async (dispatch, getState) => {
    try {
      const { data } = await apiService.post(
        `/api/v1/company/${getState().auth.current_company}/${action !== null ? `${action}/` : ''}`,
        { administrators: [values] }
      );
      if (data) {
        dispatch({ type: 'UPDATE_ADMINISTRATORS_SUCCESSFULL', data });
      }
    } catch (err) {
      //
    }
  };

const testConnectionDropBox = async (values) => {
  const { type } = values;
  let connectionStatus = false;
  const dataForSubmit = { ...values };
  delete dataForSubmit.type;
  try {
    const { data: status } = await apiService.post(
      '/api/v1/current-company/storage/check_connection/',
      {
        type,
        access_data: {
          ...dataForSubmit,
        },
      }
    );
    if (status === true) {
      NotificationManager.success('Pripojeni ke sluzbe je validni');
      connectionStatus = true;
    }
  } catch (e) {
    NotificationManager.error('Test propjení se nezdařil, zkontrolujte zadané údaje.');
  }
  return connectionStatus;
};

export const fetchStorageDetail = () => async (dispatch, getState) => {
  try {
    const { data } = await apiService.get('/api/v1/current-company/storage/');
    if (data) {
      dispatch({ type: 'FETCH_STORAGE_SUCCESSFULL', data });
    }
  } catch (e) {
    //
  }
};

export const updateStorage = (inputData) => async (dispatch, getState) => {
  let status = null;
  let error = null;
  let values = null;
  try {
    const testConnectionStatus = await testConnectionDropBox(inputData).data;

    if (testConnectionStatus) {
      const { type } = inputData;
      delete inputData.type;
      const { data, status: responseStatus } = await apiService.patch(
        '/api/v1/current-company/storage/',
        { access_data: { ...inputData }, type }
      );
      if (data) {
        values = data;
        status = responseStatus;
      }
    }
  } catch (err) {
    error = err;
  }
  return new Promise((resolve, reject) => {
    if (status && status === 200) {
      dispatch({ type: 'UPDATE_STORAGE_SUCCESSFULL', data: values });
      resolve({ status: true, error });
    }
    resolve({ status: false, error });
  });
};

export const fetchMoreEmployees = (next, search) => async (dispatch, getState) => {
  let status = null;
  let error = null;
  let values = [];
  try {
    const { data, status: responseStatus } = await getEmployees(next, search);

    if (data) {
      values = data;
      status = responseStatus;
    }
  } catch (err) {
    error = err;
  }
  return new Promise((resolve, reject) => {
    if (status && status === 200) {
      dispatch({
        type: 'EMPLOYEES_LOADED_SUCCESSFULL',
        data: values?.results,
      });
      resolve({ next: values?.next });
    }
    resolve({ next: null });
  });
};
